<template>
  <div
    class="flex w-full items-center justify-between space-x-4 md:justify-end md:space-x-6"
  >
    <a class="relative z-20 md:hidden" href="/">
      <picture class="h-full w-full">
        <img class="m-0 hidden h-5 md:block" src="/logo.svg?url" alt="logo" />
        <img class="m-0 h-8 md:hidden" src="/logo-small.svg?url" alt="logo" />
        <span class="sr-only">
          {{ t("header.title") }}
        </span>
      </picture>
    </a>

    <div class="flex items-center justify-end space-x-6 md:space-x-8">
      <SessionPopover v-if="!meta.isAuthenticated">
        <div class="flex items-center space-x-3 text-sm">
          <div class="flex flex-row items-center space-x-2 text-primary">
            <div class="hidden leading-none sm:block">
              {{ t("header.title") }}
            </div>
            <Button
              color="primary"
              variant="link"
              :label="t('header.login')"
              size="sm"
            />
          </div>
          <Avatar icon="account" size="xs" />
        </div>
      </SessionPopover>

      <div v-if="meta.isAuthenticated && user" class="flex items-center">
        <SessionDropdown>
          <Avatar
            v-bind="user.avatar"
            size="xs"
            color="primary"
            class="cursor-pointer"
          />
        </SessionDropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// --- external
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

// --- internal
import { useSession } from "@upmind/client-vue";
import { Button, Avatar } from "@upmind/upwind";

import SessionPopover from "@/views/session/components/Popover.vue";
import SessionDropdown from "@/views/session/components/Dropdown.vue";

// -----------------------------------------------------------------------------

const { t } = useI18n();
const { meta, user } = useSession();
const route = useRoute();
</script>
