<template>
  <Popover>
    <PopoverTrigger>
      <slot />
    </PopoverTrigger>
    <PopoverContent
      class="relative z-30 mt-4 h-screen w-auto border-0 border-t p-0 md:mt-8 md:h-auto md:border"
      align="end"
    >
      <div class="flex h-full flex-col md:flex-row">
        <div class="w-screen p-8 md:w-[22rem]">
          <!-- TODO: v-session refusing to react to show change -->
          <UpmSession no-header no-tabs :show="show" color="secondary" />
        </div>

        <div
          class="flex h-full w-screen items-start justify-start bg-gray-50 p-8 text-primary md:h-auto md:w-[22rem]"
        >
          <div class="w-full">
            <div v-if="show === 'login'" class="mb-4">
              <div class="text-xl">
                {{ t("session.popover.register.title") }}
              </div>
              <div class="mt-2 text-sm text-base-700">
                {{ t("session.popover.register.description") }}
              </div>
            </div>

            <div v-else class="mb-4 hidden md:block">
              <div class="text-xl">{{ t("session.popover.login.title") }}</div>
              <div class="mt-2 text-sm text-base-700">
                {{ t("session.popover.login.description") }}
              </div>
            </div>

            <div>
              <Button
                color="primary"
                :label="
                  show === 'login'
                    ? t('session.popover.register.action')
                    : t('session.popover.login.action')
                "
                block
                variant="outline"
                @click="
                  show === 'register' ? (show = 'login') : (show = 'register')
                "
              />
            </div>
          </div>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>

<script lang="ts" setup>
// --- external
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

// --- components
import { UpmSession } from "@upmind/client-vue";

import { Popover, PopoverContent, PopoverTrigger } from "@upmind/upwind";
import { Button } from "@upmind/upwind";

const show = ref("login");
</script>
