export default {
  routes: [
    {
      path: "/product/add/:pid",
      name: "productAdd",
      component: () => import("./Add.vue"),
    },
    {
      path: "/product/edit/:bpid",
      name: "productEdit",
      component: () => import("./Edit.vue"),
    },
  ],
};
