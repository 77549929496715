// --- external
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

// --- internal
import LoadingView from "./views/system/Loading.vue";
import NotFound from "./views/system/404.vue";
import { useSession } from "@upmind/client-vue";

// --- utils
import { get } from "lodash-es";

// -----------------------------------------------------
// Dynamic Routes

const importedRoutes = import.meta.glob<Object>("@/views/**/routes.ts", {
  import: "default",
  eager: true,
});

const routes: RouteRecordRaw[] = [];

for (const modules in importedRoutes) {
  const moduleRoutes = get(importedRoutes[modules], "routes", []);

  // No Promises since it's eager loaded.
  routes.push(...moduleRoutes);
}

// -----------------------------------------------------

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "loading",
      component: LoadingView,
    },
    // ---
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFound,
      meta: {
        title: "Page Not Found",
      },
    },
  ],
  scrollBehavior(to, _from, _savedPosition) {
    // handle scroll to anchor on same page
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 108,
      };
    } else {
      // always scroll to top
      return { behavior: "smooth", top: 0 };
    }
  },
});

// -----------------------------------------------------

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const { meta } = useSession();

    if (!meta.value.isAuthenticated) {
      next({
        path: "/auth",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

// -----------------------------------------------------

export default router;
