<template>
  <div
    class="relative flex min-h-screen flex-col items-start bg-background text-foreground antialiased"
    :data-theme="activeTheme"
    id="vue-app"
  >
    <VHeader shine-through />

    <main class="w-full flex-1">
      <RouterView v-slot="{ Component }" :key="$route.fullPath">
        <template v-if="Component">
          <VContent>
            <Transition mode="out-in">
              <KeepAlive>
                <Suspense>
                  <!-- main content -->
                  <component
                    :is="Component"
                    @vue:mounted="hasComponent = true"
                    @vue:unmounted="hasComponent = false"
                  ></component>

                  <!-- loading state -->
                  <template #fallback>
                    <UpmBasketLoading
                      class="min-h-screen"
                      skrim="light"
                      :text="t('basket.loading.text')"
                      :animated-icon="{
                        icon: 'basket',
                        delay: 250,
                        primaryColor: 'base-foreground',
                        secondaryColor: 'accent',
                        size: '4xl',
                      }"
                    >
                      <template #title>
                        <i18n-t
                          keypath="basket.loading.title"
                          tag="span"
                          for="basket.loading.exciting"
                          class="font-semibold text-primary"
                        >
                          <mask class="bg-accent leading-relaxed">{{
                            t("basket.loading.exciting")
                          }}</mask>
                        </i18n-t>
                      </template>
                    </UpmBasketLoading>
                  </template>
                </Suspense>
              </KeepAlive>
            </Transition>
          </VContent>
        </template>
      </RouterView>

      <UpmSessionExpired
        :title="t('session.expired.title')"
        :text="t('session.expired.text')"
        :action="{
          label: t('session.expired.actions.continue'),
          color: 'primary',
          handler: reload,
          auto: true,
        }"
      />
    </main>

    <VFooter />
  </div>

  <UpmFeedback />
</template>

<script setup>
// --- external
import { useI18n } from "vue-i18n";
import { ref } from "vue";

// --- internal
import { useThemes } from "@upmind/upwind";
import theme from "@/assets/theme";

// --- components
import {
  UpmFeedback,
  UpmSessionExpired,
  UpmBasketLoading,
} from "@upmind/client-vue";
import VHeader from "@/components/header/Header.vue";
import VContent from "@/components/content/Content.vue";
import VFooter from "@/components/footer/Footer.vue";

// -----------------------------------------------------------------------------
const { t } = useI18n();
const { activeTheme } = useThemes(theme);
const hasComponent = ref(false);

function reload() {
  window.location.reload();
}
</script>
