//  --- external
import { spawn } from "xstate";

// --- internal
import itemMachine from "../item.machine";
import { ItemActions as actions } from "./actions";
import services from "./services";

// --- utils
import {
  get,
  set,
  map,
  reduce,
  defaultsDeep,
  uniqueId,
  compact,
  pick,
  isArray,
} from "lodash-es";

// --- types
// @ts-ignore
import type { IAddress, AddressContext } from "./types";
import type { JsonSchema, UISchemaElement } from "@jsonforms/core";

// --------------------------------------------------------

export const useSchema = ({
  country,
  countries,
  regions,
  types,
  baseModel,
  // ---
  places,
  emails,
}: AddressContext) => {
  const schema = {
    type: "object",
    title: "Address Fields",
    required: ["name", "address_1", "city", "country_id", "postcode", "type"],
    // --- conditionally required fields
    if: {
      properties: {
        company_details: { const: true },
      },
      required: ["company_details"],
    },
    then: { required: ["company_name", "email", "reg_number"] },
    // ---
    properties: {
      id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of this Unified Address.",
        readOnly: true,
      },

      company_id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of the Company.",
        readOnly: true,
      },

      address_id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of the Address.",
        readOnly: true,
      },

      // ---

      place: {
        type: ["string", "null"],
        title: "Address",
        lookup: places?.search,
        // default: baseModel?.company_details && baseModel?.place,
      },

      manualPlace: {
        type: ["boolean", "null"],
        title: "Can't see your address?",
        default: baseModel?.manualPlace,
        readOnly: true,
      },

      // ---

      name: {
        type: ["string", "null"],
        title: "Address Name",
        default: baseModel?.name,
      },

      address_1: {
        type: "string",
        title: "Address Line 1",
      },

      address_2: {
        type: ["string", "null"],
        title: "Address Line 2",
      },

      city: {
        type: "string",
        title: "City",
      },

      postcode: {
        type: "string",
        title: "Postcode",
      },

      region_id: {
        type: ["string", "null"],
        title: "Region",
        oneOf: !regions?.length
          ? undefined
          : map(regions, item => {
              return {
                const: item.id,
                title: item.name,
              };
            }),
      },

      // not sure this is ever used, but it is in the type definition
      state: {
        type: ["string", "null"],
        title: "State",
      },

      country_id: {
        type: "string",
        title: "Country",
        default: baseModel?.country_id,
        oneOf: !countries?.length
          ? undefined
          : map(countries, item => {
              return {
                const: item.id,
                title: item.name,
              };
            }),
      },

      // ---

      company_details: {
        type: ["boolean", "null"],
        title: "Add business details",
        default: baseModel?.company_details,
      },

      company_name: {
        type: ["string", "null"],
        title: "Name",
      },

      email: {
        type: "string",
        title: "Email",
        default: baseModel.email,
        lookup: emails.search,
      },

      phone: {
        type: "object",
        title: "Phone",
        isPhoneNumber: country?.code,
        properties: {
          number: {
            type: ["string", "null"],
            title: "Phone number ( with dailing code )",
            default: baseModel?.phone?.number,
          },

          nationalNumber: {
            type: ["string", "null"],
            title: "Phone number",
            default: baseModel?.phone?.nationalNumber,
          },

          countryCallingCode: {
            type: ["string", "null"],
            title: "Country calling code",
            default: baseModel?.phone?.nationalCallingCode,
          },

          country: {
            type: ["string", "null"],
            title: "Country",
            default: baseModel?.phone?.country,
          },
        },
      },

      reg_number: {
        type: ["string", "null"],
        title: "Registration number",
      },

      vat_number: {
        type: ["string", "null"],
        title: "Registered tax/VAT id",
      },

      // vat_percent: {
      //   type: ["string", "null"],
      //   title: "VAT percent",
      // },
      // ---

      default: {
        type: ["boolean", "null"],
        title: "Make this the default address?",
      },

      type: {
        type: "number",
        title: "Address Type",
        default: baseModel?.type,
        oneOf: !types?.length
          ? undefined
          : map(types, item => {
              return {
                const: item.key,
                title: item.value,
              };
            }),
      },
    },
  };

  // if (id) {
  //   schema.required.push("name");
  //   schema.required.push("type");
  // }

  return schema as JsonSchema;
};

export const useUischema = ({ addresses, emails, phones }: any) => {
  const lookups = {
    addresses: reduce(
      addresses.getItems(),
      (result, item) => {
        // Only return actual addresses, NOT companies
        if (!item?.company_details) {
          // @ts-ignore
          result.push({
            value: item.id,
            label: [
              item.name,
              item.address_1,
              item.address_2,
              item.city,
              item.postcode,
            ].join(", "),
          });
        }

        return result;
      },
      []
    ),

    emails: emails.getItems(),
    phones: phones.getItems(),
  };

  const schema = {
    type: "VerticalLayout",
    elements: [
      {
        // --- address details
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/name",
            options: {
              autoFocus: true,
              autocomplete: "off",
              placeholder: "My home address, etc...",
            },
            rule: {
              effect: "DISABLE",
              condition: {
                scope: "#",
                schema: {
                  anyOf: [
                    {
                      required: ["id", "company_details"],
                      properties: { company_details: { const: true } },
                    },
                  ],
                },
              },
            },
          },
        ],
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#",
            schema: {
              required: ["id"],
            },
          },
        },
      },

      {
        // --- address details
        type: "VerticalLayout",
        elements: [
          // ---
          {
            type: "Control",
            scope: "#/properties/place",
            i18n: "client.unified.form.fields.place",
            options: {
              autoFocus: true,
              icon: "search",
              autocomplete: "off",
              align: "start",
              side: "bottom",
              placeholder: "Search for address ...",
              items: compact([
                lookups.addresses?.length
                  ? {
                      label: "Your saved addreses",
                      i18n: "client.unified.form.fields.saved",
                      as: "separator",
                    }
                  : null,
                ...lookups.addresses,
                // {
                //   label: "Enter manually",
                //   i18n: "client.unified.form.fields.manual",
                //   value: "manual",
                //   as: "button",
                //   variant: "link",
                //   size: "sm",
                //   persist: true,
                // },
              ]),
            },
            rule: {
              effect: "HIDE",
              condition: {
                scope: "#",
                schema: {
                  anyOf: [
                    { required: ["id"] },
                    {
                      required: ["place"],
                      properties: { place: { const: "manual" } },
                    },
                  ],
                },
              },
            },
          },

          // ---
          {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/address_1",
                i18n: "client.unified.form.fields.address_1",
                options: {
                  autocomplete: "address-line1",
                },
              },
              {
                type: "Control",
                scope: "#/properties/address_2",
                i18n: "client.unified.form.fields.address_2",
                options: {
                  autocomplete: "address-line2",
                },
              },

              // ---
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/city",
                    i18n: "client.unified.form.fields.city",
                    options: {
                      autocomplete: "address-level2",
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/postcode",
                    i18n: "client.unified.form.fields.postcode",
                    options: {
                      autocomplete: "postal-code",
                    },
                  },
                ],
              },
              // ---
              {
                type: "Control",
                scope: "#/properties/region_id",
                i18n: "client.unified.form.fields.region_id",
                options: {
                  autocomplete: "address-level1",
                  placeholder: "Please select a Region...",
                },
              },
              {
                type: "Control",
                scope: "#/properties/country_id",
                i18n: "client.unified.form.fields.country_id",
                options: {
                  autocomplete: "country",
                  placeholder: "Please select a Country...",
                },
              },
            ],
            // rule: {
            //   effect: "SHOW",
            //   condition: {
            //     scope: "#/properties/manualPlace",
            //     schema: { const: true },
            //   },
            // },
          },
        ],
        rule: {
          effect: "HIDE",
          condition: {
            scope: "#",
            schema: {
              anyOf: [
                {
                  required: ["id", "company_details"],
                  properties: { company_details: { const: true } },
                },
              ],
            },
          },
        },
      },

      // --- company details
      {
        type: "Control",
        scope: "#/properties/company_details",
        i18n: "client.unified.form.fields.company_details",
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#",
            schema: {
              // properties: { company_details: { not: { const: true } } },
              anyOf: [
                {
                  required: ["id"],
                  properties: { company_id: { const: null } },
                },
                {
                  required: ["name"],
                  properties: { company_id: { const: null } },
                },
                {
                  properties: {
                    manualPlace: { const: true },
                    company_id: { const: null },
                  },
                },
              ],
            },
          },
        },
      },

      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/company_name",
            i18n: "client.unified.form.fields.company_name",
            options: {
              autoFocus: true,
              autocomplete: "organization",
              placeholder: "My Company Name (PTY) LTD",
              label: "Company Name",
            },
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/reg_number",
                i18n: "client.unified.form.fields.reg_number",
                options: {},
              },
              {
                type: "Control",
                scope: "#/properties/vat_number",
                i18n: "client.unified.form.fields.vat_number",
                options: {},
              },
            ],
          },
          {
            type: "Control",
            scope: "#/properties/email",
            i18n: "client.unified.form.fields.email",
            options: {
              suggestions: true,
              autocomplete: "email",
              itemLabel: "email",
              itemValue: "email",
              items: lookups.emails,
              align: "start",
              side: "bottom",
            },
          },
          {
            type: "Control",
            scope: "#/properties/phone",
            i18n: "client.unified.form.fields.phone",
            options: {
              autocomplete: "tel",
              suggestions: true,
              itemLabel: "number",
              itemValue: "number",
              items: lookups.phones,
              align: "start",
              side: "bottom",
            },
          },
        ],
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#/properties/company_details",
            schema: { const: true },
          },
        },
      },

      // ---
      // We dont ever show this field as it is set by an action
      // {
      //   type: "Control",
      //   scope: "#/properties/default",
      //   options: {
      //     // toggle: true
      //   }
      // }
    ],
  };

  return schema as UISchemaElement;
};

export const useModelParser = (
  schema: JsonSchema,
  values: IAddress,
  baseModel?: IAddress
) => {
  const model = reduce(
    schema.properties,
    (result, field, key) => {
      const value =
        field?.const || get(values, key, field?.default || get(baseModel, key));
      set(result, key, value);
      return result;
    },
    {}
  );

  return defaultsDeep(model, values) as IAddress;
};

// --------------------------------------------------------

export const spawnItem = (model?: IAddress) => {
  try {
    const name = get(model, "id", uniqueId("item_"));
    return spawn(
      itemMachine
        // @ts-ignore
        .withConfig({
          actions,
          services,
        })
        .withContext({ model }),
      {
        name,
        sync: true,
      }
    );
  } catch (err) {
    console.error("AddressListings", "spawnItem", { model });
  }
};

export const parseAddress = (address: IAddress | Array<IAddress>) => {
  // we could get a plain address OR a company with and address
  // so we normalize the data to always be an array of addresses
  // this is to allow for a 'unfied' way of handling addresses
  const listings = isArray(address) ? address : [address];

  return map(listings, item => {
    // 1 check if we have  company_details or just an address
    if (item?.address) {
      const mappedItem = {
        id: item?.id,
        client_id: item?.client_id,
        address_id: item?.address.id, // add the address id as the unified id representing the actual address
        company_id: item?.id, // add the company id as the unified id representing the actual address
        company_details: true, // our flag to show company details
        company_name: item?.name,
        type: item?.type || 4, // default to 4 = company
        default: item?.default,
        can_delete: item?.can_delete,
        verified: item?.verified,

        // ---
        name: item?.address?.name,
        address_1: item?.address?.address_1,
        address_2: item?.address?.address_2,
        city: item?.address?.city,
        postcode: item?.address?.postcode,
        region_id: item?.address?.region_id,
        country_id: item?.address?.country_id,
        // ---
        email: item?.email?.email,
        phone: {
          number: item?.phone?.number,
          nationalNumber: item?.phone?.nationalNumber,
          countryCallingCode: item?.phone?.countryCallingCode,
          country: item?.phone?.country,
        },
        reg_number: item?.reg_number,
        vat_number: item?.vat_number,
        // vat_percent: item?.vat_percent,
      };
      return mappedItem;
    } else {
      const mappedItem: any = pick(item, [
        "id",
        "client_id",
        "name",
        "address_1",
        "address_2",
        "city",
        "postcode",
        "region_id",
        "country_id",
        "default",
        "type",
        "can_delete",
        "verified",
      ]);

      mappedItem.company_details = false;
      mappedItem.address_id = item.id;
      // mappedItem.place = null;

      return mappedItem;
    }
  });
};
