import { reduce, merge, includes } from "lodash-es";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// -----------------------------------------------------------------------------

// ---
// this will load ALL Global locales from the project assets and map them correctly
// global locales do NOT start with an underscore

export function getGlobalMessages(debug = import.meta.env.DEV) {
  let files;
  if (debug) {
    files = import.meta.glob(`@/**/i18n/[!_]*.json`, { eager: true });
  } else {
    files = import.meta.glob("@locales/**/[!_]*.json", { eager: true });
  }

  return reduce(
    files,
    (result, value, key) => {
      const locale = parseLocale(key, value?.default, debug);

      if (!locale) return result;

      merge(result, locale);
      return result;
    },
    {}
  );
}

// ---
// this will load the locales for a specific component/view base don the given name
// if we are debugging (on by default in DEV mode), we will load the source english version of the locales
export function getLocalMessages(
  name: string,
  debug: boolean = import.meta.env.DEV
) {
  let files;
  if (debug) {
    files = import.meta.glob(`@/**/i18n/_*.json`, { eager: true });
  } else {
    files = import.meta.glob(`@locales/**/_*.json`, { eager: true });
  }

  return reduce(
    files,
    (result, value, key) => {
      if (!includes(key, name)) return result;
      const locale = parseLocale(key, value?.default, debug);
      if (!locale) return result;
      merge(result, locale);
      return result;
    },
    {}
  );
}

function parseLocale(key: string, value: string, debug: boolean) {
  let locale;

  if (debug) {
    // we are in debug mode, so we will always load the english version
    locale = "en";
  } else {
    // lets do some magic to get the locale
    // our paths are like: @locales/en/xyz.json so the local is always the 2nd last part
    locale = key.split("/");
    locale = locale[locale.length - 2];
  }
  if (!locale || !value) return null;

  return { [locale]: value };
}
