<template>
  <section :class="cn('w-full', props.class)">
    <header
      :class="
        cn(
          'mb-4 ml-[1px] mt-6 flex items-end justify-between md:mb-6',
          props.classHeader
        )
      "
      v-if="title || tagline || $slots.header || $slots.title"
    >
      <slot name="header">
        <h3
          :class="
            cn(
              'm-0 !text-3xl leading-tight text-base-foreground [&:has(mask)]:leading-normal',
              props.classTitle
            )
          "
        >
          <slot name="title">{{ title }}</slot>
        </h3>
        <slot name="option">
          <div class="text-sm leading-tight text-base-700">
            {{ tagline }}
          </div>
        </slot>
      </slot>
    </header>

    <div :class="cn('w-full', props.classContent)"><slot /></div>

    <footer
      v-if="$slots.footer"
      :class="
        cn(
          'mt-6 flex flex-col space-y-2 text-xs text-base-700 md:space-y-0',
          props.classFooter
        )
      "
    >
      <slot name="footer"></slot>
    </footer>
  </section>
</template>

<script setup lang="ts">
// --- external

// --- internal
import { cn } from "@upmind/upwind";
// --- types

import type { HTMLAttributes } from "vue";

// -----------------------------------------------------------------------------
const props = defineProps<{
  title?: string;
  tagline?: string;
  class?: HTMLAttributes["class"];
  classHeader?: HTMLAttributes["class"];
  classTitle?: HTMLAttributes["class"];
  classContent?: HTMLAttributes["class"];
  classFooter?: HTMLAttributes["class"];
}>();
</script>
