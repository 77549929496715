<template>
  <div
    :class="
      cn(
        'prose z-10 mx-auto w-full max-w-app flex-wrap items-start justify-start pb-16 pt-10 md:pb-32 md:pt-16',
        props.class
      )
    "
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
// --- external

// --- internal
import { cn } from "@upmind/upwind";
// --- types
import type { HTMLAttributes } from "vue";

// -----------------------------------------------------------------------------
const props = defineProps<{
  class?: HTMLAttributes["class"];
}>();
</script>
